
import { Component, Vue } from "vue-property-decorator";
import awaitTo from 'await-to-js';
import ResetPasswordForm from "./components/ResetPasswordForm.vue";
import { FirebaseAppAuth, FirebaseAppFunctions } from "@/firebase/firebase-app";
import firebaseNames from "@/statics/firebase-names";
import { CampaignAccessTokenRequest, GenerateSignInLinkRequest } from "@/types/campaigns";
import { sharedRouteNames } from "@/route-names/shared";
import { loadCampaignByIdAndUserId } from "@/actions/campaigns/actions";
import { verifyResetPasswordCode } from '@/actions/auth/actions';

@Component({
  name: "ResetPassword",
  components: {
    ResetPasswordForm,
  },
  beforeRouteEnter: async (to, from, next) => {
    if (FirebaseAppAuth.isSignInWithEmailLink(window.location.href)) {

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const advertiserUid = urlParams.get('ADVERTISER_UID') || '';
      const campaignId = urlParams.get('ID') || ''
      const email = urlParams.get('email') || ''
      const designerAccessCode = urlParams.get('designerAccessCode') || ''

      try {
        let [err, result] = await awaitTo(FirebaseAppAuth.signInWithEmailLink(email, window.location.href));
        if (err) {
          const generateSignInFn = FirebaseAppFunctions.httpsCallable(firebaseNames.functions.GENERATE_SIGN_IN_LINK)
          const request: GenerateSignInLinkRequest = {
            designerAccessCode,
            campaignId,
            userId: advertiserUid
          }
          const res = await generateSignInFn(request)
          if (res.data.link) {
            result = await FirebaseAppAuth.signInWithEmailLink(email, res.data.link);
          } else {
            throw new Error('This link is no longer available.')
          }
        }

        const campaign = await loadCampaignByIdAndUserId(campaignId, advertiserUid);

        if (campaign.DESIGNER_EMAIL !== email) {
          throw new Error('This email is revoked');
        }
        const requestAccessTokenFn = FirebaseAppFunctions.httpsCallable(
          firebaseNames.functions.REQUEST_ACCESS_TOKEN
        );

        const uid = result?.user?.uid || ''
        const request: CampaignAccessTokenRequest = {
          UID: uid,
          CAMPAIGN_ID: campaignId,
          ADVERTISER_UID: advertiserUid,
        }

        const { data } = await requestAccessTokenFn(request);
        await FirebaseAppAuth.signInWithCustomToken(data);
        next({
          ...sharedRouteNames.DESIGN_UPLOAD, params: {
            advertiserUid,
            campaignId,
            uid,
          }
        })
        return;
      } catch (e) {
        next({ ...sharedRouteNames.PAGE_NOT_FOUND })
        console.log('error', e)
        return;
      }


    } else {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const oobCode = urlParams.get('oobCode') || ''
      try {
        await verifyResetPasswordCode(oobCode)
      } catch (error) {
        next({ ...sharedRouteNames.PAGE_NOT_FOUND })
        console.log('error', error)
      }
    }
    next();
  },
})
export default class ResetPassword extends Vue {
}
