
import { Component, Vue } from "vue-property-decorator";
import TextField from "@/ui-components/TextField/TextField.vue";
import IButton from "@/ui-components/IButton/IButton.vue";
import { rules } from "@/validation-rules/index";
import { verifyCodeAndResetPasswordAction } from "@/actions/auth/actions";
import { publicRouteNames } from "@/route-names/public";
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { LoginUserData } from "@/types/users";
import SnackBar from '@/ui-components/SnackBar/SnackBar.vue';
import { ShowSnackbar } from "@/types/snackbar";
import { Getter } from 'vuex-class';
import { UserRouteNames } from '@/types/users';

@Component({
  name: "ResetPasswordForm",
  components: {
    IButton,
    TextField,
    SnackBar
  },
})
export default class ResetPasswordForm extends Vue {
  public valid = false;
  public loading = false;
  public showPassword = false;

  public password = '';

  public eyeIcon = "/assets/icons/eye-o.svg";
  public eyeSlashIcon = "/assets/icons/eye-slash-o.svg";

  public passwordRules = [
    rules.required(this.$i18n.t('please_enter_your_password') as string),
    rules.shouldHaveEightChars(
      this.$i18n.t('password_must_have_eight_chars') as string
    ),
    rules.shouldHaveUpperAndLowerCaseChars(
      this.$i18n.t('password_must_have_upper_lower_case_chars') as string
    ),
  ];

  @Getter("userRouteNames", { namespace: namespaces.AuthModule })
  public userRouteNames!: UserRouteNames;

  public get code() {
    return this.$route.query.oobCode as string;
  }

  public async handleSuccess(loginData: LoginUserData) {
    const text = this.$t('password_changed_successfully') as string;
    const color = 'success';

    await this.loginAction(loginData);
    this.$router.push({ ...this.userRouteNames.DASHBOARD });
    this.showSnackbar({ text, color });
  }

  public handleFailure(error: any) {
    const text = this.$t('reset_password_error') as string;
    const color = 'danger';

    this.loading = false;
    this.showSnackbar({ text, color });
  }

  public async resetPassword() {
    try {
      this.loading = true;
      const loginData = await verifyCodeAndResetPasswordAction(this.code, this.password);
      await this.handleSuccess(loginData);
    } catch (error) {
      this.handleFailure(error);
    }
  }

  @Action("login", { namespace: namespaces.AuthModule })
  public loginAction: any;

  @Action("showSnackbar", { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  public created() {
    if (!this.code) {
      this.$router.replace(publicRouteNames.LOGIN);
    }
  }
}
